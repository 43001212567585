define("ember-ref-modifier/modifiers/ref", ["exports", "@ember/object", "@ember/debug", "@ember/modifier", "@ember/runloop"], function (_exports, _object, _debug, _modifier, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function hasValidTarget(target) {
    return typeof target === 'object' && target !== null && !Array.isArray(target);
  }
  function hasValidProperty(prop) {
    return typeof prop === 'string';
  }
  function getParams([target, propName]) {
    if (typeof target === 'function') {
      return {
        cb: target
      };
    }
    (true && !(typeof target !== 'string') && (0, _debug.assert)(`String ${target} used as context for ref modifier. Should be {{ref context "${target}"}}. You passed {{ref "${target}" context}}`, typeof target !== 'string'));
    return {
      target,
      propName
    };
  }
  var _default = _exports.default = (0, _modifier.setModifierManager)(() => ({
    _runCache: new Set(),
    capabilities: _modifier.capabilities ? (0, _modifier.capabilities)('3.13') : undefined,
    createModifier() {
      return {
        element: undefined,
        propName: undefined,
        cb: undefined,
        target: undefined
      };
    },
    installModifier(state, element, {
      positional
    }) {
      const {
        propName,
        target,
        cb
      } = getParams(positional);
      if (cb) {
        state.cb = cb;
        this._runInContext(cb, element);
        return;
      }
      if (hasValidProperty(propName) && hasValidTarget(target)) {
        this._setInContext(target, propName, element);
        state.propName = propName;
        state.target = target;
      }
      state.element = element;
    },
    updateModifier(state, {
      positional
    }) {
      const {
        propName,
        target,
        cb
      } = getParams(positional);
      if (cb) {
        state.cb = cb;
        this._runInContext(cb, state.element);
        return;
      }
      if (hasValidProperty(propName) && hasValidTarget(target)) {
        if (hasValidProperty(state.propName) && hasValidTarget(state.target)) {
          if ((0, _object.get)(target, propName) !== (0, _object.get)(state.target, state.propName)) {
            this._setInContext(state.target, state.propName, null);
          }
        }
        this._setInContext(target, propName, state.element);
        state.propName = propName;
        state.target = target;
      }
    },
    _setInContext(target, propName, value) {
      const cancelToken = (0, _runloop.next)(this, '_setValues', target, propName, value);
      this._runCache.add(cancelToken);
    },
    _runInContext(cb, value) {
      const cancelToken = (0, _runloop.next)(this, '_runCb', cb, value);
      this._runCache.add(cancelToken);
    },
    _runCb(cb, value) {
      cb(value);
    },
    _setValues(target, propName, value) {
      if (target.isDestroyed || target.isDestroying) {
        return;
      }
      (0, _object.set)(target, propName, value);
    },
    destroyModifier({
      target,
      propName,
      cb
    }) {
      this._runCache.forEach(cancelToken => (0, _runloop.cancel)(cancelToken));
      if (cb) {
        return;
      }
      if (hasValidProperty(propName) && hasValidTarget(target)) {
        this._setInContext(target, propName, null);
      }
    }
  }), class RefModifier {});
});