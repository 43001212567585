define("ember-yeti-table/components/yeti-table/thead/row/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "iQZDQNy4",
    "block": "[[[11,\"tr\"],[16,0,[29,[[30,1],\" \",[30,2,[\"theadRow\"]],\" \",[30,2,[\"row\"]]]]],[17,3],[12],[1,\"\\n  \"],[18,7,[[28,[37,2],null,[[\"column\",\"cell\"],[[50,\"ember-yeti-table@yeti-table/thead/row/column/component\",0,null,[[\"sortable\",\"sortSequence\",\"onClick\",\"theme\",\"parent\"],[[30,0,[\"sortable\"]],[30,4],[30,5],[30,2],[30,6]]]],[50,\"ember-yeti-table@yeti-table/thead/row/cell/component\",0,null,[[\"theme\",\"parent\"],[[30,2],[30,0]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@trClass\",\"@theme\",\"&attrs\",\"@sortSequence\",\"@onColumnClick\",\"@parent\",\"&default\"],false,[\"tr\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/thead/row/template.hbs",
    "isStrictMode": false
  });
});