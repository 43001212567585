define("ember-yeti-table/components/yeti-table/tbody/row/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4rpu1Mad",
    "block": "[[[11,\"tr\"],[16,0,[29,[[30,1,[\"tbodyRow\"]],\" \",[30,1,[\"row\"]]]]],[16,\"role\",[52,[30,2],\"button\"]],[17,3],[4,[38,2],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[18,4,[[28,[37,4],null,[[\"cell\"],[[50,\"ember-yeti-table@yeti-table/tbody/row/cell/component\",0,null,[[\"theme\",\"parent\"],[[30,1],[30,0]]]]]]]]],[1,\"\\n\"],[13]],[\"@theme\",\"@onClick\",\"&attrs\",\"&default\"],false,[\"tr\",\"if\",\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/tbody/row/template.hbs",
    "isStrictMode": false
  });
});