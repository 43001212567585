define("ember-yeti-table/components/yeti-table/tfoot/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ky9whA1V",
    "block": "[[[11,\"tfoot\"],[16,0,[30,1,[\"tfoot\"]]],[17,2],[12],[1,\"\\n  \"],[18,5,[[28,[37,2],null,[[\"row\"],[[50,\"ember-yeti-table@yeti-table/tfoot/row/component\",0,null,[[\"columns\",\"theme\",\"parent\"],[[30,3],[30,1],[30,4]]]]]]]]],[1,\"\\n\"],[13]],[\"@theme\",\"&attrs\",\"@columns\",\"@parent\",\"&default\"],false,[\"tfoot\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/tfoot/template.hbs",
    "isStrictMode": false
  });
});