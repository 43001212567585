define("ember-yeti-table/components/yeti-table/pagination/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "GQztn7Bs",
    "block": "[[[11,0],[16,0,[30,1,[\"pagination\",\"controls\"]]],[17,2],[12],[1,\"\\n\"],[41,[30,0,[\"showInfo\"]],[[[1,\"    \"],[10,0],[15,0,[30,1,[\"pagination\",\"info\"]]],[12],[1,\"\\n      Showing \"],[1,[30,3,[\"pageStart\"]]],[1,\" to \"],[1,[30,3,[\"pageEnd\"]]],[1,\" of \"],[1,[30,3,[\"totalRows\"]]],[1,\" entries\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showPageSizeSelector\"]],[[[1,\"    \"],[10,0],[15,0,[30,1,[\"pagination\",\"pageSize\"]]],[12],[1,\"\\n      Rows per page:\\n      \"],[11,\"select\"],[16,\"disabled\",[30,4]],[4,[38,3],[\"change\",[30,0,[\"changePageSize\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pageSizes\"]]],null]],null],null,[[[1,\"          \"],[10,\"option\"],[15,2,[30,5]],[15,\"selected\",[28,[37,7],[[30,3,[\"pageSize\"]],[30,5]],null]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[5]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showButtons\"]],[[[1,\"    \"],[11,\"button\"],[16,0,[30,1,[\"pagination\",\"previous\"]]],[16,\"disabled\",[30,0,[\"shouldDisablePrevious\"]]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,6,[\"previousPage\"]]],null],[12],[1,\"\\n      Previous\\n    \"],[13],[1,\"\\n\\n    \"],[11,\"button\"],[16,0,[30,1,[\"pagination\",\"next\"]]],[16,\"disabled\",[30,0,[\"shouldDisableNext\"]]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,6,[\"nextPage\"]]],null],[12],[1,\"\\n      Next\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@theme\",\"&attrs\",\"@paginationData\",\"@disabled\",\"pageSize\",\"@paginationActions\"],false,[\"div\",\"if\",\"select\",\"on\",\"each\",\"-track-array\",\"option\",\"yeti-table-eq\",\"button\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/pagination/template.hbs",
    "isStrictMode": false
  });
});